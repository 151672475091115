/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiTeamLine, RiBuilding4Line, RiLineChartLine, RiListCheck2 } from "react-icons/ri"

const OrganizationalHealthAssessmentDownloadPage = () => {
  
  // Assessment categories and dimensions
  const assessmentCategories = {
    leadership: {
      title: "Leadership Effectiveness",
      dimensions: [
        "Strategic direction is clear and well-communicated",
        "Leaders model company values and expected behaviors",
        "Decision-making is transparent and consistent",
        "Leaders are accessible and receptive to feedback",
        "Change is managed effectively with appropriate support"
      ]
    },
    culture: {
      title: "Organizational Culture",
      dimensions: [
        "Core values are clearly defined and consistently lived",
        "Psychological safety enables honest communication",
        "Collaboration across teams and departments is effective",
        "Innovation and appropriate risk-taking are encouraged",
        "Diversity, equity and inclusion are prioritized and advanced"
      ]
    },
    execution: {
      title: "Operational Execution",
      dimensions: [
        "Roles and responsibilities are clear across the organization",
        "Processes and systems support efficient work completion",
        "Resources are allocated appropriately to strategic priorities",
        "Goals and priorities are clearly communicated",
        "Performance metrics are aligned with organizational objectives"
      ]
    },
    talent: {
      title: "Talent Management",
      dimensions: [
        "The organization effectively attracts and retains top talent",
        "Professional development opportunities are accessible and relevant",
        "Performance management practices are consistent and fair",
        "Recognition and rewards align with organizational values",
        "Career paths and advancement opportunities are transparent"
      ]
    }
  }
  
  // Rating scale for assessment
  const ratingScale = [
    { score: 5, label: "Excellent", description: "Exceptional strength; a model for other organizations" },
    { score: 4, label: "Strong", description: "Clear strength with minimal room for improvement" },
    { score: 3, label: "Adequate", description: "Functional but with some opportunity for enhancement" },
    { score: 2, label: "Developing", description: "Significant gaps exist; needs improvement" },
    { score: 1, label: "Critical", description: "Major deficiency requiring immediate attention" }
  ]
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Organizational Health Assessment | Diagnostic Tool"
        description="Evaluate your organization's effectiveness with our comprehensive health assessment tool. Identify strengths and improvement areas across leadership, culture, execution, and talent."
        keywords={[
          "organizational health assessment",
          "company culture evaluation",
          "leadership effectiveness survey",
          "organizational effectiveness tool",
          "talent management assessment",
          "operational execution evaluation",
          "organizational diagnostic tool",
          "company health check",
          "workplace assessment framework",
          "organizational development diagnostic"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/organizational-development" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Organizational Development Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Organizational Health Assessment
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive diagnostic tool to evaluate your organization's effectiveness across key dimensions
              and identify specific areas for improvement.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Assessment
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Conduct an Organizational Health Assessment?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              An organizational health assessment provides a data-driven evaluation of your organization's strengths 
              and areas for improvement. Unlike performance evaluations that focus solely on outcomes, this assessment 
              examines the underlying systems, practices, and culture that drive sustainable success. 
              Regular assessments help identify emerging issues before they impact performance and provide a roadmap for 
              strategic improvement initiatives.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Identify Strengths & Gaps",
                  description: "Uncover what's working well and where improvements are needed",
                  icon: <RiCheckLine />
                },
                {
                  title: "Align Leadership",
                  description: "Create a shared understanding of organizational health",
                  icon: <RiUserLine />
                },
                {
                  title: "Prioritize Initiatives",
                  description: "Focus resources on the most impactful improvement areas",
                  icon: <RiListCheck2 />
                },
                {
                  title: "Track Progress",
                  description: "Measure improvements in organizational effectiveness over time",
                  icon: <RiLineChartLine />
                }
              ].map((benefit, i) => (
                <div key={`benefit-${i}`} sx={{
                  bg: 'background',
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    {benefit.icon}
                  </div>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2
                  }}>
                    {benefit.title}
                  </h3>
                  <p sx={{
                    fontSize: '0.9rem',
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Assessment Framework */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Assessment Framework
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              This assessment evaluates your organization across four critical dimensions that research has shown to be 
              key predictors of organizational health and long-term performance. Each dimension includes multiple 
              specific indicators that are rated on a 5-point scale.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {Object.keys(assessmentCategories).map((key, i) => {
                const category = assessmentCategories[key];
                return (
                  <div key={`category-${i}`} sx={{
                    bg: 'background',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                  }}>
                    <h3 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      {category.title}
                    </h3>
                    <ul sx={{
                      pl: 3,
                      mb: 0
                    }}>
                      {category.dimensions.map((dimension, j) => (
                        <li key={`dimension-${i}-${j}`} sx={{ mb: 1, fontSize: '0.9rem', lineHeight: 1.5 }}>
                          {dimension}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </section>
          
          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Assessment Template Preview
            </h2>
            
            <div sx={{
              bg: 'white',
              p: [3, 4],
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              {/* Organization Information */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Organization Information
                </h3>
                
                <div sx={{ 
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3
                }}>
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Organization Name:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }} />
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Department/Unit (if applicable):</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }} />
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Assessment Date:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }} />
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Completed By:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }} />
                  </div>
                </div>
              </div>
              
              {/* Rating Scale */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Rating Scale
                </h3>
                
                <table sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '0.9rem'
                }}>
                  <thead>
                    <tr sx={{
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <th sx={{ p: 2, textAlign: 'left', width: '15%' }}>Score</th>
                      <th sx={{ p: 2, textAlign: 'left', width: '25%' }}>Rating</th>
                      <th sx={{ p: 2, textAlign: 'left', width: '60%' }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingScale.map((rating, i) => (
                      <tr 
                        key={`rating-${i}`}
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'muted',
                          bg: i % 2 === 0 ? 'white' : '#f8f9fa'
                        }}
                      >
                        <td sx={{ p: 2, fontWeight: 600 }}>
                          {rating.score}
                        </td>
                        <td sx={{ p: 2 }}>
                          {rating.label}
                        </td>
                        <td sx={{ p: 2 }}>
                          {rating.description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              {/* Example Assessment Section */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Leadership Effectiveness Assessment
                </h3>
                
                <table sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '0.9rem',
                  mb: 3
                }}>
                  <thead>
                    <tr sx={{
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <th sx={{ p: 2, textAlign: 'left', width: '60%' }}>Dimension</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>1</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>2</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>3</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>4</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>5</th>
                      <th sx={{ p: 2, textAlign: 'center', width: '15%' }}>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentCategories.leadership.dimensions.map((dimension, i) => (
                      <tr 
                        key={`leadership-dimension-${i}`}
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'muted',
                          bg: i % 2 === 0 ? 'white' : '#f8f9fa'
                        }}
                      >
                        <td sx={{ p: 2 }}>
                          {dimension}
                        </td>
                        {[1, 2, 3, 4, 5].map(score => (
                          <td key={`score-option-${score}`} sx={{ p: 2, textAlign: 'center' }}>
                            <div sx={{
                              width: '20px',
                              height: '20px',
                              border: '1px solid',
                              borderColor: 'muted',
                              borderRadius: '50%',
                              mx: 'auto'
                            }} />
                          </td>
                        ))}
                        <td sx={{ p: 2, textAlign: 'center' }}>
                          <div sx={{ 
                            height: '1.8rem',
                            border: '1px solid',
                            borderColor: 'muted',
                            borderRadius: '4px'
                          }} />
                        </td>
                      </tr>
                    ))}
                    <tr sx={{
                      borderBottom: '1px solid',
                      borderColor: 'muted',
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <td sx={{ p: 2, textAlign: 'right' }} colSpan={6}>
                        Leadership Effectiveness Average:
                      </td>
                      <td sx={{ p: 2, textAlign: 'center' }}>
                        <div sx={{ 
                          height: '1.8rem',
                          border: '1px solid',
                          borderColor: 'muted',
                          borderRadius: '4px',
                          bg: 'white'
                        }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <div>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Strengths &amp; Areas for Improvement:</div>
                  <div sx={{ 
                    height: '5rem',
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '4px',
                    p: 2
                  }} />
                </div>
              </div>
              
              <p sx={{ 
                fontSize: '0.9rem', 
                fontStyle: 'italic',
                textAlign: 'center',
                color: 'text',
                opacity: 0.8
              }}>
                Note: The complete assessment includes all four key dimensions with detailed evaluation criteria, 
                an overall health score calculation, priority identification matrix, and action planning tools.
              </p>
            </div>
          </section>
          
          {/* How to Use the Assessment */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              How to Use This Assessment
            </h2>
            
            <ol sx={{
              pl: 4,
              mb: 4
            }}>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Gather a diverse assessment team</strong> - Include leaders from different levels and departments
                to ensure multiple perspectives.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Rate each dimension honestly</strong> - Be candid about strengths and weaknesses. The assessment 
                is only valuable if it reflects reality.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Collect supporting evidence</strong> - Document examples that support your ratings to make the 
                assessment more objective.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Discuss results as a team</strong> - Review assessment findings together to build shared understanding 
                and alignment.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Prioritize improvement areas</strong> - Focus on the dimensions that will have the most significant 
                impact on organizational performance.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Develop action plans</strong> - Create specific initiatives with clear responsibilities and timelines.
              </li>
              <li sx={{ mb: 2, lineHeight: 1.5 }}>
                <strong>Reassess periodically</strong> - Conduct the assessment regularly (e.g., annually) to track progress 
                and identify new improvement opportunities.
              </li>
            </ol>
          </section>
          
          {/* Closing CTA */}
          <section>
            <div sx={{
              bg: 'primary',
              color: 'white',
              p: 4,
              borderRadius: '8px',
              textAlign: 'center'
            }}>
              <h2 sx={{ 
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3 
              }}>
                Ready to Strengthen Your Organization?
              </h2>
              
              <p sx={{ mb: 4, lineHeight: 1.6, fontSize: '1.1rem' }}>
                Download the complete Organizational Health Assessment with all assessment dimensions and implementation guidance.
              </p>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 4,
                  borderRadius: '6px',
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.2)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Assessment
              </Link>
            </div>
          </section>
        </div>
      </div>
      
      {/* Print Styles */}
      <style jsx="true">{`
        @media print {
          .print-hide {
            display: none !important;
          }
          body {
            font-size: 12pt;
          }
          a {
            text-decoration: none !important;
            color: #000 !important;
          }
        }
      `}</style>
    </Layout>
  )
}

export default OrganizationalHealthAssessmentDownloadPage 